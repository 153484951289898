import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, mergeProps as _mergeProps, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-578a3808"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "buildPage" }
const _hoisted_2 = { class: "cardFormTop" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = {
  key: 0,
  class: "cardDetails"
}
const _hoisted_5 = { class: "cardDetailsTabContent" }
const _hoisted_6 = { style: {"padding":"10px 30px 10px 10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_switch = _resolveComponent("el-switch")!
  const _component_el_form = _resolveComponent("el-form")!
  const _component_SelectTag = _resolveComponent("SelectTag")!
  const _component_el_tab_pane = _resolveComponent("el-tab-pane")!
  const _component_el_tabs = _resolveComponent("el-tabs")!
  const _component_FieldCfg = _resolveComponent("FieldCfg")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_el_form, {
          ref: el=>_ctx.refMap.set('formRef',el),
          model: _ctx.pageData.config,
          rules: _ctx.rules,
          "label-width": "100px"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, { label: "主表" }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.pageData.config.tableName,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.pageData.config.tableName) = $event)),
                          style: {"width":"100%"},
                          onChange: _ctx.tableChange
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData.allTables, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.tableName,
                                label: item.tableChineseName,
                                value: item.tableName
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue", "onChange"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "模块",
                      prop: "modelPath"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.pageData.config.modelPath,
                          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageData.config.modelPath) = $event)),
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherParams.allPathMenu, (item) => {
                              return (_openBlock(), _createBlock(_component_el_option, {
                                key: item.value,
                                label: item.label,
                                value: item.value
                              }, null, 8, ["label", "value"]))
                            }), 128))
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "名称",
                      prop: "modelName"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_input, {
                          onInput: _cache[2] || (_cache[2] = e => _ctx.pageData.config.modelName = _ctx.valid(e)),
                          placeholder: "请输入名称",
                          modelValue: _ctx.pageData.config.modelName,
                          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageData.config.modelName) = $event)),
                          maxlength: "25",
                          clearable: ""
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            _createVNode(_component_el_row, null, {
              default: _withCtx(() => [
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "列数",
                      prop: "colNum"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_select, {
                          modelValue: _ctx.pageData.config.colNum,
                          "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageData.config.colNum) = $event)),
                          style: {"width":"100%"}
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_option, {
                              label: "1",
                              value: 1
                            }),
                            _createVNode(_component_el_option, {
                              label: "2",
                              value: 2
                            }),
                            _createVNode(_component_el_option, {
                              label: "3",
                              value: 3
                            })
                          ]),
                          _: 1
                        }, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_el_col, { span: 8 }, {
                  default: _withCtx(() => [
                    _createVNode(_component_el_form_item, {
                      label: "是否全屏",
                      prop: "fullscreen"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_el_switch, {
                          modelValue: _ctx.pageData.config.fullscreen,
                          "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.pageData.config.fullscreen) = $event))
                        }, null, 8, ["modelValue"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["model", "rules"]),
        (_ctx.pageData.config.tableName)
          ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
              _createVNode(_component_SelectTag, _mergeProps(_ctx.pageData.listCondition, {
                type: "listCondition",
                name: "查询字段",
                onDbHandler: _ctx.dbHandler,
                onCloseItemHandler: _ctx.closeItem,
                onChangeHandler: _ctx.changeHandler
              }), null, 16, ["onDbHandler", "onCloseItemHandler", "onChangeHandler"]),
              _createVNode(_component_SelectTag, _mergeProps(_ctx.pageData.listGrid, {
                type: "listGrid",
                name: "表格字段",
                onDbHandler: _ctx.dbHandler,
                onCloseItemHandler: _ctx.closeItem,
                onChangeHandler: _ctx.changeHandler
              }), null, 16, ["onDbHandler", "onCloseItemHandler", "onChangeHandler"]),
              _createVNode(_component_SelectTag, _mergeProps(_ctx.pageData.cardForm, {
                type: "cardForm",
                name: "表单字段",
                onDbHandler: _ctx.dbHandler,
                onCloseItemHandler: _ctx.closeItem,
                onChangeHandler: _ctx.changeHandler
              }), null, 16, ["onDbHandler", "onCloseItemHandler", "onChangeHandler"])
            ]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.pageData.cardDetails.tables.length!=0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_el_tabs, {
              closable: "",
              modelValue: _ctx.otherParams.cardDetails.field.tableName,
              "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.otherParams.cardDetails.field.tableName) = $event)),
              onTabRemove: _ctx.removeTab
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData.cardDetails.tables, (item) => {
                  return (_openBlock(), _createBlock(_component_el_tab_pane, {
                    label: item.tableChineseName,
                    name: item.tableName
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_5, [
                        _createVNode(_component_el_row, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_col, {
                              span: 2,
                              class: "myColTitle",
                              style: {"text-align":"right"}
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode("标题：")
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_el_col, { span: 9 }, {
                              default: _withCtx(() => [
                                _createVNode(_component_el_input, {
                                  onInput: e => item.tableChineseName = _ctx.valid(e),
                                  placeholder: "请输入标题",
                                  modelValue: item.tableChineseName,
                                  "onUpdate:modelValue": ($event: any) => ((item.tableChineseName) = $event),
                                  clearable: ""
                                }, null, 8, ["onInput", "modelValue", "onUpdate:modelValue"])
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1024),
                        _createVNode(_component_SelectTag, {
                          fieldArr: item.fieldArr,
                          allFields: item.fields,
                          "data-tablename": item.tableName,
                          tableName: item.tableName,
                          type: "cardDetails",
                          name: "字段",
                          onDbHandler: _ctx.dbHandler,
                          onCloseItemHandler: _ctx.closeItem,
                          onChangeHandler: _ctx.changeHandler
                        }, null, 8, ["fieldArr", "allFields", "data-tablename", "tableName", "onDbHandler", "onCloseItemHandler", "onChangeHandler"])
                      ])
                    ]),
                    _: 2
                  }, 1032, ["label", "name"]))
                }), 256))
              ]),
              _: 1
            }, 8, ["modelValue", "onTabRemove"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_FieldCfg, _mergeProps({
      fieldItem: _ctx.otherParams.fieldItem
    }, _ctx.otherParams.fieldCfgParam, {
      ref: el=>_ctx.refMap.set('fieldCfgRef',el),
      onFieldInfoSure: _cache[7] || (_cache[7] = ($event: any) => (_ctx.utilInst.fieldInfoSure(_ctx.otherParams.fieldCfgParam.cfgName)))
    }), null, 16, ["fieldItem"]),
    _createVNode(_component_el_dialog, {
      modelValue: _ctx.otherParams.cardDetails.visible,
      "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.otherParams.cardDetails.visible) = $event)),
      "append-to-body": "true",
      draggable: "",
      width: "40%",
      center: "",
      "close-on-click-modal": false
    }, {
      footer: _withCtx(() => [
        _createVNode(_component_el_button, {
          class: "iconfont icon-shanchu",
          onClick: _cache[9] || (_cache[9] = ($event: any) => (_ctx.otherParams.cardDetails.visible=false))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('cancel')), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_button, {
          type: "primary",
          class: "iconfont icon-queding",
          onClick: _cache[10] || (_cache[10] = ($event: any) => (_ctx.utilInst.addTabSure()))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t('confirm')), 1)
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_select, {
            modelValue: _ctx.otherParams.cardDetails.addTableName,
            "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.otherParams.cardDetails.addTableName) = $event))
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pageData.allTables, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  key: item.tableName,
                  label: item.tableChineseName,
                  value: item.tableName
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ])
      ]),
      _: 1
    }, 8, ["modelValue"])
  ], 64))
}