import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, mergeProps as _mergeProps, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-311a0d54"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "selectTag" }
const _hoisted_2 = { class: "sorterOuter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_tag = _resolveComponent("el-tag")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_el_row, { style: {"margin-top":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_el_col, {
          span: 2,
          class: "myColTitle"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.name) + "：", 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_col, { span: 22 }, {
          default: _withCtx(() => [
            _createVNode(_component_el_select, {
              modelValue: _ctx.otherParams.fieldArr,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.otherParams.fieldArr) = $event)),
              "value-key": "label",
              placeholder: "请选择字段列",
              style: {"width":"100%"},
              multiple: "",
              "collapse-tags": "",
              "collapse-tags-tooltip": "",
              "max-collapse-tags": 3,
              onRemoveTag: _ctx.removeHandler,
              onChange: _ctx.changeHandler
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.allFields, (field) => {
                  return (_openBlock(), _createBlock(_component_el_option, {
                    key: field.prop,
                    label: field.label,
                    value: field
                  }, null, 8, ["label", "value"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["modelValue", "onRemoveTag", "onChange"])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.otherParams.fieldArr, (field) => {
        return (_openBlock(), _createBlock(_component_el_tag, _mergeProps({
          class: "sorterItem",
          size: "large",
          closable: "",
          "data-type": _ctx.type,
          "data-id": field.prop,
          "data-props": field.props,
          ref_for: true
        }, _ctx.$attrs, {
          onDblclick: ($event: any) => (_ctx.dbHandler(field,_ctx.type,_ctx.tableName)),
          onClose: ($event: any) => (_ctx.closeItem(field.prop,_ctx.type,_ctx.tableName))
        }), {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(field.label), 1)
          ]),
          _: 2
        }, 1040, ["data-type", "data-id", "data-props", "onDblclick", "onClose"]))
      }), 256))
    ])
  ]))
}